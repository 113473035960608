import superagent from 'superagent'
import axios from 'axios'
import { encrypt } from '@/utils/des'
import { host, tokenPrefix } from './host'
export const userLogin = (source = '01', token = 'AAFInOjJADCppEXkOhcwi3Dd6f1ceFIIOWFakYPovT6x4vgOLYpdFeglaF1qpDoDn5g09M9y5cQ') => {
    // 'AAFInOjJADCppEXkOhcwi3Dd6f1ceFIIOWFakYPovT6x4vgOLYpdFeglaF1qpDoDn5g09M9y5cQ'
    return new Promise((resolve, reject) => {
        axios.post(`${host}/login/${source}`, {}, {
            headers: {
                Authorization: token
            }
        })
        .then((res) => {
            resolve(res.data.data)
        }, (err) => {
            reject(err)
        })
    })
}

export const getUserInfo = (user_id) => {
    return new Promise((resolve, reject) => {
        superagent.get(`${host}/user/${user_id}`)
           .set('token', encrypt(`${tokenPrefix}.user`))
           .send({})
           .then((res) => {
                resolve(res.body)
            }, (err) => {
                reject(err)
            })
    })
}

export const registerUser = (userInfo) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/user/register`, userInfo, {
            headers: {
                AssessKey: encrypt(`${userInfo.user_name}.user`)
            }
        })
        .then((res) => {
            resolve(res.data)
        }, (err) => {
            reject(err)
        })
    })
}