import React, { useState, useEffect, useContext, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import './index.less'
import TextArea from '@/components/TextArea'
import IconTitle from '@/components/IconTitle'
import IconQA from '@/components/IconQA'
import AddPKSku from '@/HOC/AddPKSku'
import Button from '@/components/Button'
import { SkuChooseContext, ToastContext, QAContext } from '@/context/context'
import { createQuestion } from '@/server/QAManager'
import { sendEmail } from '@/server/mailManager'
import _ from 'lodash'
import { isIncludeSensitive } from '@/utils'

export default function QuestionPage() {
    const { getSelectedSku, initSelectedSku } = useContext(SkuChooseContext)
    const { show } = useContext(ToastContext)
    const { getSaveQuestionText, setSaveQuestion } = useContext(QAContext)
    const textAreaRef = useRef(null)
    initSelectedSku(2)
    const [questionText, setQuestionText] = useState('')
    const [skuInfoLeft, setSkuInfoLeft] = useState({})
    const [skuInfoRight, setSkuInfoRight] = useState({})
    const navigate = useNavigate()

    useEffect(() => {
        // 每次回到该页面的时候，获取用户在商品选择页面选择的两个商品
        initSelectedSku(2) // 初始化两个坑位
        const selectedSkus = getSelectedSku()
        setSkuInfoLeft(selectedSkus[0] || {})
        setSkuInfoRight(selectedSkus[1] || {})
        const saveText = getSaveQuestionText()
        setQuestionText(saveText)
        textAreaRef.current.elem.value = saveText
        return () => {}
    }, [])

    if (!window.userInfo) {
        setTimeout(() => {
            navigate('/')
        })
        return
    }

    return (
        <div className="question-page-wrap flex-col items-center">
            <TextArea
                ref={textAreaRef}
                IconElem={<IconQA theme="Q" />}
                style={{
                    marginTop: '0.04rem',
                }}
                onChange={(text) => {
                    setQuestionText(text)
                    setSaveQuestion(text)
                }}
            />
            <IconTitle
                title="添加2个商品 让大家帮你选"
                IconElem={<img
                    className="pk-icon"
                    src={require('@/assets/imgs/pk.svg').default}
                />}
                style={{
                    marginTop: '0.16rem',
                    marginBottom: '0.12rem'
                }}
            />
            <AddPKSku
                onAddLeft={() => {
                    navigate('/choose', {
                        state: {
                            navigatePage: '/question',
                            chooseIndex: 0,
                        },
                    })
                }}
                onAddRight={() => {
                    navigate('/choose', { state: {
                        navigatePage: '/question',
                        chooseIndex: 1,
                    } })
                }}
                skuInfoLeft={skuInfoLeft}
                skuInfoRight={skuInfoRight}
            />
            <Button
                text="发布问题"
                icon={require('@/assets/imgs/纸飞机.svg').default}
                size="large"
                style={{
                    position: 'absolute',
                    bottom: '0.21rem',
                }}
                onClick={() => {
                    if (isIncludeSensitive(questionText)) {
                        show('内容包含敏感词汇', 1200)
                    } else {
                        createQuestion({
                            user_id:  window.userInfo.user_id,
                            user_name: window.userInfo.user_name,
                            text: questionText,
                            products: _.isEmpty(skuInfoLeft) || _.isEmpty(skuInfoRight) ? [] : [{
                                sku_id: skuInfoLeft.sku_id,
                                image_url: skuInfoLeft.image_url,
                                // name: skuInfoLeft.name,
                                vote: 0,
                                voters: [],
                            }, {
                                sku_id: skuInfoRight.sku_id,
                                image_url: skuInfoRight.image_url,
                                // name: skuInfoRight.name,
                                vote: 0,
                                voters: [],
                            }]
                        }).then(() => {
                            sendEmail()
                            show('发布成功等待审核', 1000, () => {
                                window.history.back()
                            })
                        })
                    }
                }}
                disabled={questionText.length === 0 || (_.isEmpty(skuInfoLeft) && !_.isEmpty(skuInfoRight)) || (!_.isEmpty(skuInfoLeft) && _.isEmpty(skuInfoRight))}
            />
        </div>
    )
}