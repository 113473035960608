import React from 'react'
import IconQA from '../IconQA'
import SkuCard from '../SkuCard'
import './index.less'
import _ from 'lodash'

export default function Comment({
    style = {},
    showIcon = true,
    avatar = require('@/assets/imgs/纸飞机.svg').default,
    nickName = '京东用户',
    isOfficial = true,
    content = '建议购买拉菲传奇波尔多750ml的收藏！',
    skuList = [{
        name: '白酒',
        image_url: '//m.360buyimg.com/babel/jfs/t1/244097/34/1262/21051/658e674bF4c0beace/5dfe5850d6a596ce.png'
    }, {
        name: '酒1',
        image_url: '//m.360buyimg.com/babel/jfs/t1/246345/3/1226/137183/658e674aFd758c41a/191fc3488bed7e65.png'
    }, {
        name: '酒2',
        image_url: '//m.360buyimg.com/babel/jfs/t1/230447/30/10848/19847/658e674aF6c344fa6/ca015022c183b229.png'
    }], // name, img, skuId
    cardSize = {
        width: '1.07rem',
        height: '1.39rem',
    },
    onClickSku = () => {}
}) {
    return (
        <div
            className="comment-wrap flex-row"
            style={Object.assign({}, style)}
        >
            {
                showIcon && (
                    <IconQA
                        theme="A"
                        style={{
                            flexShrink: 0,
                            marginRight: '0.04rem',
                            marginTop: '0.02rem'
                        }}
                    />
                )
            }
            <div
                className="main-content"
            >
                <div className="user-info items-center">
                    {
                        isOfficial ? (
                            <>
                                <img
                                    className="avatar official"
                                    src={require('@/assets/imgs/京东狗.png')}
                                    alt=""
                                />
                                <span className="official">酒行品鉴官</span>
                                <div className="official-label items-center">
                                    <img src={require('@/assets/imgs/大V.svg').default} alt="" />
                                    官方号
                                </div>
                            </>
                            
                        ) : (
                            <>
                                <img
                                    className="avatar"
                                    src={avatar}
                                    alt=""
                                />
                                <span>{nickName}</span>
                            </>
                        )
                    }
                    
                </div>
                <div className="comment">{content}</div>
                {
                    skuList.length > 0 && (
                        <div
                            className="justify-start sku-wrap"
                        >
                            {
                                skuList.map((item, index) => {
                                    return (
                                        !_.isEmpty(item) && <SkuCard
                                            key={index}
                                            src={item.image_url}
                                            title={item.name}
                                            onClick={() => {
                                                onClickSku(item)
                                            }}
                                            style={{
                                                width: cardSize.width,
                                                height: cardSize.height,
                                                marginLeft: index === 0 ? 0 : '0.06rem',
                                            }}
                                        />
                                    )
                                })
                            }
                        </div>
                    )
                }
            </div>
        </div>
    )
}