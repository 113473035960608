import { useEffect, useContext } from 'react'
import { Routes, Route, HashRouter } from 'react-router-dom'
import './App.less'
import Main from './pages/Main'
import VConsole from 'vconsole'
import PersonalCenter from './pages/PersonalCenter';
import QuestionDetail from './pages/QuestionDetail';
import AnswerPage from './pages/Answer';
import ChooseSku from './pages/ChooseSku';
import QuestionPage from './pages/QuestionPage'
import { SkuChooseProvider, ToastProvider, User, QA } from './context/provider';
import { isIncludeSensitive } from './utils';
import { callApp, registerCode, toLogin } from '@jmfe/jd-jssdk'
import { registerUser } from './server/userManager';
import { sendTrack } from './server/track';

// 官方账号
window.officialInfo = {
  user_name: '酒行品鉴官',
  user_id: 'user-bf1f69c266c2808261396e1e540cb3ab0772ccc3d340cc04f580dc4459b07b60',
  user_avatar: '#'
}

// const vconsole = new VConsole()

const loadTimestamp = Date.now()

function App() {
  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      if (window.userInfo && document.visibilityState === 'hidden') {
        sendTrack({
          stay_duration: (Date.now() - loadTimestamp) / 1000,
          user_id: window.userInfo.user_id,
          user_name: window.userInfo.user_name,
        })
      }
    })
  }, [])

  return (
    <div className="App">
        <QA>
          <User>
            <SkuChooseProvider>
              <ToastProvider>
                <HashRouter>
                  <Routes>
                    <Route path="/" element={<Main />} />
                    <Route path="/center" element={<PersonalCenter />} />
                    <Route path="/detail" element={<QuestionDetail />} />
                    <Route path="/answer" element={<AnswerPage />} />
                    <Route path="/choose" element={<ChooseSku />} />
                    <Route path="/question" element={<QuestionPage />} />
                  </Routes>
                </HashRouter>
              </ToastProvider>
            </SkuChooseProvider>
          </User>
        </QA>

    </div>
  );
}

export default App;