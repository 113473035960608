import React from 'react'
import _ from 'lodash'
import './index.less'
import SkuCardWithClose from '@/components/SkuCardWithClose'
import AddSku from '@/components/AddSku'

export default function AddSkuCard({
    skuInfo = {},
    onAdd = () => {},
    onDelete = () => {},
}) {
    return (
        <div
            className="add-sku-card-wrap"
        >
            {
                _.isEmpty(skuInfo) && (
                    <AddSku onAdd={onAdd} />
                )
            }
            {
                _.isEmpty(skuInfo) === false && (
                    <SkuCardWithClose
                        style={{
                            width: '100%',
                            height: '100%,'
                        }}
                        src={skuInfo.image_url}
                        title={skuInfo.title}
                        onClose={() => {
                            onDelete()
                        }}
                    />
                )
            }
        </div>
    )

}