import React from 'react'
import './index.less'
import SkuCard from '../SkuCard'

export default function SkuCardWithClose(props = {
    style: {
        backgroundColor: '#ededed'
    },
    src: require('@/assets/imgs/画笔.svg').default,
    title: '拉菲 传奇波尔多典藏版 750ml ',
    onClick: () => { },
    onClose: () => { },
}) {
    return (
        <div
            className="sku-card-with-close-wrap justify-center items-center"
        >
            <div
                className="close-wrap items-center justify-center"
            >
                <img
                    src={require('@/assets/imgs/close.svg').default}
                    alt=""
                    onClick={props.onClose}
                />
            </div>

            <SkuCard {...props} style={{
                backgroundColor: '#ededed'
            }} />
        </div>
    )
}