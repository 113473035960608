import React from 'react'
import './index.less'

export default function VotePKLine({
    width = '3.41rem',
    lVote = 100,
    rVote = 50,
}) {
    rVote = rVote === 0 ? 0.1 : rVote
    lVote = lVote === 0 ? 0.1 : lVote
    return (
        <div
            className="vote-pk-line flex-row"
            style={{
                width,
                height: '0.34rem'
            }}
        >

            <div
                className="left flex-row"
                style={{
                    width: `${100 * lVote / (lVote + rVote)}%`,
                }}
            >
                <div
                    className="line"
                    style={{
                        width: `calc(100% - 0.05rem)`
                    }}
                ></div>
                <img
                    style={{
                        width: '0.06rem',
                        height: '100%',
                        margin: 0,
                    }}
                    alt=""
                    src="//m.360buyimg.com/babel/jfs/t1/226618/1/8233/542/657bb4d6F1ddaf776/42116e8910c01f7a.png"
                />
                <span>{lVote.toFixed(0)}票</span>
            </div>
            <div
                style={{
                    width: `${100 * rVote / (lVote + rVote)}%`
                }}
                className="right"
            >
                <div
                    className="line"
                    style={{
                        width: `calc(100% - 0.06rem)`
                    }}
                ></div>
                <img
                    style={{
                        position: 'absolute',
                        left: '-0.06rem',
                        width: '0.06rem',
                        height: '100%',
                    }}
                    alt=""
                    src="//m.360buyimg.com/babel/jfs/t1/235280/9/7552/601/657bb4d6F3406f1c7/b1ad038812de5bcd.png"
                />
                <span>{rVote.toFixed(0)}票</span>
            </div>
        </div>
    )
}