import React, { useRef, useState } from 'react'
import './index.less'

export default function Search({
    placeholder = '搜索商品',
    style = {},
    onChangeEnd = () => { },
    searchResult = [],
    onClickResult = () => {},
    onClickSearchBtn = () => {},
    onClickClear = () => {}
}) {
    const timer = useRef(null)
    const inputRef = useRef(null)
    const [searchInput, setSearchInput] = useState('')
    const [isFocus, setIsFocus] = useState(false)
    const highlightText = (text = '') => {
        const regExp = new RegExp(searchInput, 'g')
        return text.replace(regExp, `<span class="highlight">${searchInput}</span>`);
    }
    return (
        <div
            className="search-wrap items-center"
            style={style}
        >
            <div
                className="items-center input-area"
            >
                <img
                    className="icon"
                    src={require('@/assets/imgs/放大镜.svg').default}
                    alt=""
                />
                <input
                    ref={inputRef}
                    className="input"
                    type="text"
                    placeholder={placeholder}
                    onFocus={() => {
                        setIsFocus(true)
                    }}
                    onBlur={() => {
                        setIsFocus(false)
                    }}
                    onChange={(e) => {
                        // 防抖
                        clearTimeout(timer.current)
                        timer.current = setTimeout(() => {
                            onChangeEnd(e.target.value)
                            setSearchInput(e.target.value)
                        }, 500)
                    }}
                />
                {
                    (searchInput.length > 0) && (
                        <>
                            <img
                                className="clear"
                                src={require('@/assets/imgs/close.svg').default}
                                onClick={() => {
                                    inputRef.current.value = ''
                                    setSearchInput('')
                                    onClickClear()
                                }}
                            />
                            <div
                                className="search-btn items-center justify-center"
                                onClick={
                                    onClickSearchBtn
                                }
                            >搜索</div>
                        </>
                    )
                }
            </div>
            {
                searchInput.length > 0 && (
                    <div className="result-list">
                        <div className="content-wrap">
                            {
                                searchResult.map((result, index) => {
                                    return (
                                        <div
                                            className="content items-center"
                                            key={index}
                                            onClick={() => {
                                                onClickResult(index)
                                            }}
                                        >
                                            <img
                                                className="icon"
                                                style={{
                                                    width: '0.14rem',
                                                    height: '0.14rem',
                                                }}
                                                src={require('@/assets/imgs/放大镜灰色.svg').default}
                                                alt=""
                                            />
                                            <span className="text" dangerouslySetInnerHTML={{ __html: highlightText(result) }}></span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                )
            }

        </div>
    )
}