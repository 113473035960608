import React from  'react'
import './index.less'

export default function AddSku({
    onAdd = () => {},
    style = {
        width: '1.07rem',
        height: '1.42rem',
    }
}) {
    return (
        <div
            className="add-sku flex-col items-center justify-center"
            onClick={onAdd}
            style={style}
        >
            <img
                src={require('@/assets/imgs/加号.svg').default}
                alt=""
                className="plus"
            />
            <span>添加商品</span>
        </div>
    )
}