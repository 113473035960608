import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import './index.less'
import IconTitle from '@/components/IconTitle'
import IconQA from '@/components/IconQA'
import VoteButton from '@/components/VoteButton'
import VotePKLine from '@/components/VotePKLine'
import Comment from '@/components/Comment'

export default function QABox({
    title = "PK｜1000元以内有哪瓶葡萄酒更适合个人收藏？",
    style = {},
    pk = {
        pro1: {
            name: '拉菲传奇波尔多1750ml',
            vote: 60,
            image_url: require('@/assets/imgs/纸飞机.svg').default,
        },
        pro2: {
            name: '拉菲传奇波尔多750ml',
            vote: 90,
            image_url: require('@/assets/imgs/大V.svg').default,
        },
    },
    comment = {
        // nickName: '酒行品鉴官',
        // avatar: require('@/assets/imgs/放大镜.svg').default,
        // content: '',
        // skuList: [],
        // isOfficial: true
    },
    disabled = false,
    onVoteLeft = () => {},
    onVoteRight = () => {},
    onClickLeft = () => {},
    onClickRight = () => {},
    defaultLeftVoted = false,
    defaultRightVoted = false,
}) {
    console.log(defaultLeftVoted, defaultRightVoted, 'defaultRightVoteddefaultRightVoted')
    const [lVote, setLVote] = useState(pk?.pro1?.vote)
    const [rVote, setRVote] = useState(pk?.pro2?.vote)
    const [isLVoted, setIsLVoted] = useState(defaultLeftVoted)
    const [isRVoted, setIsRVoted] = useState(defaultRightVoted)
    useEffect(() => {
        console.log(pk, 'pk pk pk pk pkpk pk ')
        setLVote(pk?.pro1?.vote)
        setRVote(pk?.pro2?.vote)
    }, [pk])
    return (
        <div className="qa-box-wrap" style={style}>
            <IconTitle IconElem={<IconQA theme="Q" />} title={title} />
            {
                !_.isEmpty(pk) && (
                    <>
                        <div className="pk">
                            <div className="shop-item lft flex-col items-center">
                                <img src={pk.pro1.image_url} alt="" onClick={onClickLeft}  />
                                <div className="title">{pk.pro1.name}</div>
                            </div>
                            <div className="shop-item rgt flex-col items-center" >
                                <img src={pk.pro2.image_url} alt="" onClick={onClickRight} />
                                <div className="title">{pk.pro2.name}</div>
                            </div>
                            <div className="vote-btn-lft">
                                <VoteButton
                                    theme="gold"
                                    onClick={() => {
                                        onVoteLeft(() => {
                                            setLVote(lVote + 1)
                                            setIsLVoted(true)
                                        })
                                    }}
                                    disabled={isRVoted || disabled}
                                    isVoted={isLVoted}
                                />
                            </div>
                            <div className="vote-btn-rgt">
                                <VoteButton
                                    onClick={() => {
                                        onVoteRight(() => {
                                            setRVote(rVote + 1)
                                            setIsRVoted(true)
                                        })
                                    }}
                                    disabled={isLVoted || disabled}
                                    isVoted={isRVoted}
                                />
                            </div>
                        </div>

                        <VotePKLine
                            lVote={lVote}
                            rVote={rVote}
                        />
                    </>
                )
            }
            {
                _.isEmpty(pk) && !_.isEmpty(comment) && (
                    <Comment
                        style={{
                            marginTop: '0.05rem',
                        }}
                        cardSize={{
                            width: '1rem',
                            height: '1rem',
                        }}
                        nickName={comment.user_name}
                        avatar={comment.user_avatar}
                        content={comment.text}
                        isOfficial={comment.isOfficial}
                        skuList={comment.skuList}
                    />
                )
            }

        </div>
    )
}