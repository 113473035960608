import React from 'react'
import './index.less'
import IconQA from '../IconQA'

export default function IconTitle({
    style = {},
    IconElem = <IconQA />,
    title = "",
}) {
    return (
        <div
            className="icon-title-wrap"
            style={style}
        >
            {IconElem}
            <span className="title">
                {title}
            </span>
        </div>
    )
}