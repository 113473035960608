import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.less';
import App from './App';

document.documentElement.style.fontSize = document.body.clientWidth / 375 * 100 + 'px'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);

