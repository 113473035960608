import React from 'react'
import './index.less'

export default function IconQA({
    theme = 'Q',
    style = {}
}) {
    return (
        <div
            style={Object.assign({}, style)}
            className="icon-a justify-center items-center"
        >
            <div className="icon">
                <img
                    src={theme === 'Q' ? require('@/assets/imgs/红色框.svg').default : require('@/assets/imgs/金色框.svg').default}
                    alt=""
                />
                <span>{ theme == 'Q' ? '问' : '答' }</span>
            </div>
            
        </div>
    )
}