import React, { useState } from 'react'
import CX from 'classnames'
import './index.less'

// state 未投票 已投票
export default function VoteButton({
    theme = 'blue',
    disabled = false,
    isVoted = false,
    onClick = () => {}
}) {
    const [voted, setVoted] = useState(isVoted)
    return (
        <div
            className={CX({
                'vote-button': true,
                'justify-center': true,
                'items-center': true,
                gold: theme === 'gold',
            })}
            onClick={() => {
                if (!disabled && voted === false) {
                    onClick()
                }
            }}
        >
            <span
                className={CX({
                    disabled,
                })}
            >{voted ? '已投票' : '投一票'}</span>
            {
                voted && (
                    <img
                        className="icon"
                        src={
                            theme === 'gold'
                                ? require('@/assets/imgs/金色勾.svg').default
                                : require('@/assets/imgs/蓝色勾.svg').default
                        }
                        alt=""
                    />
                )
            }
        </div>
    )
}