import React, { useRef, useState } from 'react'
export const UserContext = React.createContext({})

export default function User({ children }) {
    const initData = {
        user_id: '',
        user_name: '',
        gendar: '0',
        user_avatar: '',
    }
    const userData = useRef(initData)
    return (
        <UserContext.Provider
            value={{
                userData: userData.current,
                setUserData: (newData = {}) => {
                    userData.current = newData
                },
            }}
        >
            {children}
        </UserContext.Provider>
    )
}