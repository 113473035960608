import React from 'react'
import './index.less'
import AddSku from '@/components/AddSku'
import SkuCard from '@/components/SkuCard'
import _ from 'lodash'

export default function AddPKSku({
    onAddLeft = () => { },
    onAddRight = () => { },
    skuInfoLeft = {},
    skuInfoRight = {},
}) {
    return (
        <div className="add-pk-sku">
            <div className="left pk-sku-wrap" onClick={onAddLeft}>
                {
                    _.isEmpty(skuInfoLeft) ? <AddSku
                        style={{
                            width: '1.37rem',
                            height: '1.37rem',
                        }}
                    /> : <img
                        src={skuInfoLeft.image_url}
                        alt=""
                        className="sku-img"
                    />
                }
            </div>
            <div className="right pk-sku-wrap" onClick={onAddRight}>
                {
                    _.isEmpty(skuInfoRight) ? <AddSku
                        style={{
                            width: '1.37rem',
                            height: '1.37rem',
                        }}
                    /> : <img
                        src={skuInfoRight.image_url}
                        alt=""
                        className="sku-img"
                    />
                }
            </div>
        </div>
    )
}