import React from 'react'
import './index.less'

export default function SkuCard({
    style = {},
    src = '',
    title = '',
    onClick = () => {}
}) {
    return (
        <div
            className="sku-card-wrap flex-col items-center justify-center"
            style={style}
            onClick={onClick}
        >
            <img
                className="sku-card-img"
                src={src}
                alt=""
            />
            <div className="sku-card-text">
                {title}
            </div>
        </div>
    )
}