import React, { useContext, useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import './index.less'
import NavigateSelect from '@/components/NavigateSelect'
import QABox from '@/HOC/QABox'
import { searchQuestion, getReplyAnswerList } from '@/server/QAManager'
import { SkuChooseContext, QAContext } from '@/context/context'
import { getUserInfo } from '@/server/userManager'

export default function PersonalCenter() {
    const renderQIdGrp = useRef([])
    const navigate = useNavigate()
    const [selfQuestion, setSelfQuestion] = useState([])
    const [joinQuestion, setJoinQuestion] = useState([])
    const [tab, setTab] = useState(0)
    const { getSkuById } = useContext(SkuChooseContext)
    const { setCurrentQuestion } = useContext(QAContext)

    const updateSearchResult = async (result) => {
        const _selfQuestion = []
        const _joinQuestion = []
        for (let i = 0; i < result.length; i++) {
            const item = result[i]
            const allVoters = []
            const allCommenter = []
            // 如果是已经渲染过的 则不去查找问题的评论
            if (!renderQIdGrp.current.includes(item.id)) {
                let comments = await getReplyAnswerList(item.id)
                comments = comments.filter((comment) => {
                    return comment.audit_phase === 'pass'
                })
                item.comments = comments
                item.comment = comments.length > 0 ? comments[0] : {}
                if (_.isEmpty(item.comment) === false) {
                    const { user_name, user_avatar } = await getUserInfo(item.comment.user_id)
                    item.comment.skuList = JSON.parse(item.comment.product_recommend)
                    item.comment = Object.assign(item.comment, {
                        user_name,
                        user_avatar,
                        isOfficial: item.comment.user_id === window.officialInfo.user_id,
                    })
                }
            }
            if (item.products.length !== 0) {
                item.products.forEach((product) => {
                    allVoters.push(...product.voters.map(voter => voter.user_id))
                    product.name = getSkuById(product.sku_id) ? getSkuById(product.sku_id).name : ''
                })
            }
            allCommenter.push(...item.comments.map(comment => comment.user_id))
            // 如果参与过投票或者评论则放到"我参与的"这一栏中
            if (allVoters.includes(window.userInfo.user_id) || allCommenter.includes(window.userInfo.user_id)) {
                _joinQuestion.push(item)
            }
            if (item.user_id === window.userInfo.user_id) {
                _selfQuestion.push(item)
            }
            renderQIdGrp.current.push(item.id)
        }
        renderQIdGrp.current = [...new Set(renderQIdGrp.current)]
        setSelfQuestion(_selfQuestion)
        setJoinQuestion(_joinQuestion)
    }

    useEffect(() => {
        document.title = '我的问答'
        return () => {
            document.title = '美酒问答'
        }
    }, [])

    useEffect(() => {
        // HACK 暂时后端不支持过滤 自己弄1000000条过滤
        searchQuestion({}, 1, 1000000).then((list) => {
            list = list.filter(item => item.audit_phase === 'pass')
            updateSearchResult(list)
        })
    }, [])

    const renderList = tab === 0 ? selfQuestion : joinQuestion

    if (!window.userInfo) {
        setTimeout(() => {
            navigate('/')
        })
        return
    }

    return (
        <div className="personal-center-wrap">
            <NavigateSelect
                onSelect={(index) => {
                    // 选择tab
                    setTab(index)
                }}
                style={{
                    marginLeft: '0.1rem',
                }}
            />
            <div className="content flex-col items-center">
                {
                    renderList.map((info, index) => {
                        return (
                            <div
                                key={index}
                                style={{
                                    marginTop: index === 0 ? 0 : '0.08rem'
                                }}
                                className="box-wrap"
                            >
                                
                                <QABox
                                    pk={info.products.length > 1 ? {
                                        pro1: info.products[0],
                                        pro2: info.products[1],
                                    } : {}}
                                    title={info.text}
                                    comment={info.comment}
                                />
                                <div
                                    className="cover"
                                    onClick={() => {
                                        setCurrentQuestion(info)
                                        navigate('/detail')
                                    }}
                                ></div>
                            </div>
                        )
                    })
                }
            <div className="bottom-space"></div>
 
            </div>
        </div>
    )
}