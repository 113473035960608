import superagent from 'superagent'
import { encrypt } from '@/utils/des'
import { host, tokenPrefix } from './host'
import _ from 'lodash'

export const createQuestion = ({
    user_id = '123',
    text = '',
    user_name = '',
    products = [],
    name = '',
    audit_phase = 'pending'
}) => {
    return new Promise((resolve, reject) => {
        superagent.post(`${host}/question/create`)
            .set('AssessKey', encrypt(`${user_name}.question`))
            .send({
                create_time: Date.now(),
                text,
                user_id,
                products,
                user_name,
                audit_phase,
            })
            .then(() => {
                resolve()
            }, (err) => {
                reject(err)
            })
    })
}

export const searchQuestion = (filter = {}, page = 1, num = 10) => {
    return new Promise((resolve, reject) => {
        superagent.post(`${host}/question/search/${page}/${num}`)
            .set('token', encrypt(`${tokenPrefix}.question`))
            .send(filter)
            .then((res) => {
                if (res.body.err === 0) {
                    resolve(res.body.data)
                }
            }, (err) => {
                reject(err)
            })
    })
}

export const updateQuestion = (new_params, token) => {
    return new Promise((resolve, reject) => {
        superagent.post(`${host}/question/update`)
            .set('AssessKey', encrypt(`${token}.question`))
            .send(new_params)
            .then((res) => {
                resolve(res.body)
            }, (err) => {
                reject(err)
            })
    })
}

export const addProductVoter = (voter, question_id, product_index) => {
    return searchQuestion({question_id}, 1, 10).then((res) => {
        res = res[0]
        if (res && res.products.length > 0 && window.userInfo.user_name) {
            const params = res
            params.user_name = window.userInfo.user_name
            params.question_id = question_id
            params.products[product_index].voters.push(voter)
            return updateQuestion(params, window.userInfo.user_name)
        }
    }, (err) => {
        return err
    })
}

// export const getQuestionList = (page = 1, num = 100) => {
//     return new Promise((resolve, reject) => {
//         superagent.get(`${host}/question/list/${page}/${num}`)
//             .set('token', encrypt(`${tokenPrefix}.question`))
//             .send({})
//             .then((res) => {
//                 if (res.body.err === 0) {
//                     resolve(res.body)
//                 } else {
//                     reject()
//                 }
//             }, (err) => {
//                 reject(err)
//             })
//     })
// }

export const replyQuestion = ({
    question_id,
    user_id,
    user_name,
    text = '',
    product_recommend = '',
    audit_phase = 'pending',
    create_time = Date.now(),
}) => {
    return new Promise((resolve, reject) => {
        superagent.post(`${host}/question/reply`)
            .set('AssessKey', encrypt(`${user_name}.question`))
            .send({
                question_id,
                user_id,
                text,
                user_name,
                product_recommend,
                audit_phase,
                create_time
            })
            .then((res) => {
                if (res.body.err === 0) {
                    resolve(res.body)
                } else {
                    reject()
                }
            }, (err) => {
                reject(err)
            })
    })
}

export const getReplyAnswerList = (question_id, page = 1, num = 1024) => {
    return new Promise((resolve, reject) => {
        superagent.post(`${host}/answer/list/${page}/${num}`)
            .set('token', encrypt(`${tokenPrefix}.answer`))
            .send({
                question_id,
            })
            .then((res) => {
                if (res.body.err === 0) {
                    resolve(res.body.data)
                }
            }, (err) => {
                reject(err)
            })
    })
}

// export const getReplyAnswerList = (user_id, page = 1, num = 1024) => {
//     return new Promise((resolve, reject) => {
//         superagent.post(`${host}/answer/list/${page}/${num}`)
//             .set('token', encrypt(`${tokenPrefix}.answer`))
//             .send({
//                 user_id,
//             })
//             .then((res) => {
//                 if (res.body.err === 0) {
//                     resolve(res.body.data)
//                 }
//             }, (err) => {
//                 reject(err)
//             })
//     })
// }