import React, { useRef, useState } from 'react'
import Toast from '@/components/Toast'
export const ToastContext = React.createContext({})

export default function ToastProvider({ children }) {
    const [showToast, setShowToast] = useState(false)
    const textRef = useRef('')
    const showTimer = useRef(null)
    return (
        <ToastContext.Provider
            value={{
                show: (text, timeout = 800, cb = () => {}) => {
                    clearTimeout(showTimer.current)
                    textRef.current = text
                    setShowToast(true)
                    showTimer.current = setTimeout(() => {
                        setShowToast(false)
                        cb()
                    }, timeout)
                }
            }}
        >
            <Toast
                show={showToast}
                text={textRef.current}
            />
            {children}
        </ToastContext.Provider>
    )
}