import React, { useRef, useState } from 'react'
export const QAContext = React.createContext({})

export default function QA({ children }) {
    const inputQuestion = useRef('')
    const inputAnswer = useRef('')
    const currentQuestion = useRef({})
    return (
        <QAContext.Provider
            value={{
                clearSaveText: () => {
                    inputQuestion.current = ''
                    inputAnswer.current = ''
                },
                getSaveQuestionText: () => {
                    return inputQuestion.current
                },
                setSaveQuestion: (text) => {
                    inputQuestion.current = text
                },
                getSaveAnswerText: () => {
                    return inputAnswer.current
                },
                setSaveAnswer: (text) => {
                    inputAnswer.current = text
                },
                getCurrentQuestion: () => {
                    return currentQuestion.current
                },
                setCurrentQuestion: (data) => {
                    currentQuestion.current = data
                }
            }}
        >
            {children}
        </QAContext.Provider>
    )
}