import React, { useState, useImperativeHandle, useRef } from 'react'
import './index.less'
import IconQA from '../IconQA'

export default React.forwardRef(function TextArea({
    IconElem = <IconQA
        theme="A"
    />,
    placeholder="请输入回答",
    style = {},
    onChange = () => {},
}, ref) {
    const textAreaRef = useRef(null)
    const [text, setText] = useState('')
    useImperativeHandle(ref, () => ({
        text,
        elem: textAreaRef.current
    }))
    return (
        <div
            className="text-area-wrap flex-row"
            style={style}
        >
            {IconElem}
            <textarea
                ref={textAreaRef}
                className="text-area"
                name="answer"
                id=""
                cols="30"
                rows="10"
                placeholder={placeholder}
                maxLength={100}
                onChange={(e) => {
                    setText(e.target.value)
                    onChange(e.target.value)
                }}
            ></textarea>
            <div
                className="num"
            >{text.length}/100字</div>
        </div>
    )
})