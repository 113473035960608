import React, { useRef, useState, useEffect } from 'react'
export const SkuChooseContext = React.createContext({})

export default function SkuChooseProvider({ children }) {
    const selectedSku = useRef([])
    const isInit = useRef(false)
    const [allSkuList, setAllSkuList] = useState([])
    const _allSkuList = useRef([])
    useEffect(() => {
        _allSkuList.current = allSkuList
    }, [allSkuList])
    return (
        <SkuChooseContext.Provider
            value={{
                allSkuList,
                setAllSkuList: (newList) => {
                    setAllSkuList(newList)
                },
                getSkuById: (skuId) => {
                    for (let i = 0; i < _allSkuList.current.length; i++) {
                        if (_allSkuList.current[i].sku_id === skuId) {
                            return _allSkuList.current[i]
                        }
                    }
                    return null
                },
                initSelectedSku: (len) => {
                    if (isInit.current === true) return
                    for (let i = 0; i < len; i++) {
                        selectedSku.current.push({})
                    }
                    isInit.current = true
                },
                deleteSelectedSku: (index) => {
                    if (!isInit.current) {
                        return
                    }
                    if (selectedSku.current[index]) {
                        selectedSku.current[index] = {}
                    }
                },
                setSelectedSku: (sku, index) => {
                    if (!isInit.current) {
                        return
                    }
                    selectedSku.current[index] = sku
                },
                getSelectedSku: () => {
                    if (!isInit.current) {
                        return
                    }
                    return selectedSku.current
                },
                clearSelectedSku: () => {
                    if (!isInit.current) return
                    selectedSku.current = []
                    isInit.current = false
                }
            }}
        >
            {children}
        </SkuChooseContext.Provider>
    )
}