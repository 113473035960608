import superagent from 'superagent'
import { encrypt } from '@/utils/des'
import { host, tokenPrefix } from './host'

// 
export const sendTrack = (track) => {
    fetch(`${host}/page/statistics`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'AssessKey': encrypt(`${track.user_name}.statistics`)
        },
        body: JSON.stringify(track),
        keepalive: true,
    })
}

export const getTrack = () => {
    return new Promise((resolve, reject) => {
        superagent.get(`${host}/page/statistics`)
            .set('token', encrypt(`${tokenPrefix}.statistics`))
            .send({})
            .then((res) => {
                resolve(res)
            }, (err) => {
                reject(err)
            })
    })
}