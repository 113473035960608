import React, { useState, useEffect, useContext, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import './index.less'
import Search from '@/components/SearchWithResult'
import QABox from '@/HOC/QABox'
import Button from '@/components/Button'
import { SkuChooseContext, UserContext, QAContext } from '@/context/context';
import { userLogin, getUserInfo } from '@/server/userManager'
import { getAllProduct } from '@/server/productManager'
import {
    searchQuestion,
    getReplyAnswerList
} from '@/server/QAManager'
import { registerCode, toLogin, isJDAppLogin, requestIsvToken, isApp } from '@jmfe/jd-jssdk'

let registerHashWatcher = false
// 先注册code 才能使用jd-jssdk里的webview能力 code的获取方法 https://opendoc.jd.com/jd-jssdk/v4/jd-webview.html#registercode
// code: 337120d3-5d44-45f7-b157-ce8ebd21cb62
registerCode('337120d3-5d44-45f7-b157-ce8ebd21cb62')

const mock_user = {
    "user_id": "user-82721d844c04f22502244a87061313e08d49c6e8d3fbee47e12201880a5ce6cb",
    "user_name": "zhangsan",
    "user_avatar": "//passport.jd.com/new/misc/skin/df/i/no-img_mid_.jpg",
    "gendar": "0"
}

export default function Main() {
    const [qaList, setQaList] = useState([])
    const [searchResult, setSearchResult] = useState([])
    const navigate = useNavigate()
    const { clearSelectedSku, setAllSkuList, allSkuList, getSkuById } = useContext(SkuChooseContext)
    const { setUserData } = useContext(UserContext)
    const { clearSaveText, setCurrentQuestion } = useContext(QAContext)
    const lockPage = useRef(true)
    const scrollElem = useRef(null)
    const renderQIdGrp = useRef([])
    const allQuestionList = useRef([])
    const scrollEventLock = useRef(false)
    const currentPage = useRef(1)
    const updateSearchResult = async (result) => {
        // 搜索框搜索结果渲染出来
        for (let i = 0; i < result.length; i++) {
            const item = result[i]
            if (item.products.length === 0) {
                let comments = await getReplyAnswerList(item.id)
                comments = comments.filter((comment) => {
                    return comment.audit_phase === 'pass'
                })
                item.comment = comments.length > 0 ? comments[0] : {}
                if (_.isEmpty(item.comment) === false) {
                    const { user_name, user_avatar } = await getUserInfo(item.comment.user_id)
                    item.comment.skuList = JSON.parse(item.comment.product_recommend)
                    item.comment = Object.assign(item.comment, {
                        user_name,
                        user_avatar,
                        isOfficial: item.comment.user_id === window.officialInfo.user_id,
                    })
                }
            } else {
                item.comment = {}
                item.products.forEach((product) => {
                    product.name = getSkuById(product.sku_id) ? getSkuById(product.sku_id).name : ''
                })
            }
            renderQIdGrp.current.push(item.id)
        }
        renderQIdGrp.current = [...new Set(renderQIdGrp.current)]
        setQaList(result)
    }
    useEffect(() => {
        if (registerHashWatcher) return
        const hashChange = (e) => {
            const oldURLName = e.oldURL.split('/').pop()

            // TODO: 待优化
            // 如果离开question页面或answer页面，重制在question/answer页面选择的商品
            if (oldURLName === 'question' || oldURLName === 'answer') {
                clearSelectedSku()
                clearSaveText()
            }
        }
        window.addEventListener('hashchange', hashChange, false)
        registerHashWatcher = true
    }, [])
    useEffect(() => {
        // 滚动到底部之后自动加载10条数据
        const elem = scrollElem.current
        const _scroll = (e) => {
            if (scrollEventLock.current === true) return
            const { scrollTop, scrollHeight, clientHeight } = e.target
            if (scrollTop + clientHeight + 10 >= scrollHeight) {
                const res = allQuestionList.current.slice((currentPage.current - 1) * 10, currentPage.current * 10)
                if (res.length < 10) {
                    scrollEventLock.current = true
                }
                updateSearchResult(qaList.concat(res))
                currentPage.current += 1
            }
        }
        elem.addEventListener('scroll', _scroll, false)
        return () => {
            if (elem) {
                elem.removeEventListener('scroll', _scroll, false)
            }
        }
    }, [qaList])
    const initQuestionList = () => {
        // 初始化问题列表，考虑到体量较小，一次直接全部获取。
        currentPage.current = 1
        return searchQuestion({}, 1, 1000001).then((res) => {
            allQuestionList.current = res.filter((item) => item.audit_phase === 'pass')
            updateSearchResult(allQuestionList.current.slice((currentPage.current - 1) * 10, currentPage.current * 10))
            currentPage.current += 1
        })
    }
    useEffect(() => {
        // 1.先检查是否登录
        // 2.登录后获取用户信息 例如code
        // isJDAppLogin
        window.isJDApp = isApp('jd')
        if (window.isJDApp) {
            isJDAppLogin().then(({ data }) => {
                if (data === '1') {
                    // 京东用户成功登陆
                    requestIsvToken(window.location.href).then(({ status, data }) => {
                        if (status === '0') {
                            // 根据京东用户信息标识，获取该用户存放在我们数据库内的信息
                            userLogin('01', data).then((res) => {
                                lockPage.current = false
                                window.userInfo = res
                                setUserData(res)
                                // 获取所有商品组信息
                                if (allSkuList.length === 0) {
                                    getAllProduct().then((list) => {
                                        setAllSkuList(list)
                                        initQuestionList()
                                    })
                                } else {
                                    initQuestionList()
                                }
                            })
                        }
                    })
                } else if (data === '0') {
                    // 登录失败则跳转京东登录页面
                    toLogin()
                }
            })
        } else {
            // 本地测试或其他端测试
            userLogin().then((res) => {
                lockPage.current = false
                window.userInfo = mock_user
                setUserData(mock_user)
                if (allSkuList.length === 0) {
                    getAllProduct().then((list) => {
                        setAllSkuList(list)
                        initQuestionList()
                    })
                } else {
                    initQuestionList()
                }
            }, () => {
                lockPage.current = false
                window.userInfo = mock_user
                setUserData(mock_user)
                if (allSkuList.length === 0) {
                    getAllProduct().then((list) => {
                        setAllSkuList(list)
                        initQuestionList()
                    })
                } else {
                    initQuestionList()
                }
            })
        }

    }, [])
    return (
        <div className="main-page-wrap flex-col items-center">
            <Search
                style={{
                    marginTop: '0.06rem',
                    marginBottom: '0.08rem',
                }}
                placeholder="请输入问题"
                onChangeEnd={(text) => {
                    searchQuestion(text === '' ? {} : { text }, 1, 1000001).then((res) => {
                        allQuestionList.current = res.filter((item) => item.audit_phase === 'pass')
                        currentPage.current = 1
                        setSearchResult(allQuestionList.current)
                    })
                }}
                searchResult={searchResult.map(n => n.text)}
                onClickClear={() => {
                    initQuestionList()
                }}
                onClickSearchBtn={() => {
                    updateSearchResult(searchResult)
                }}
                onClickResult={(idx) => {
                    setCurrentQuestion(searchResult[idx])
                    navigate('detail')
                }}
            />
            <div
                className="qa-area"
                ref={scrollElem}
            >
                {
                    qaList.map((info, index) => {
                        return (
                            <div
                                key={index}
                                style={{
                                    marginTop: index === 0 ? 0 : '0.08rem'
                                }}
                                className="box-wrap"
                            >
                                <QABox
                                    pk={info.products.length > 1 ? {
                                        pro1: info.products[0],
                                        pro2: info.products[1],
                                    } : {}}
                                    title={info.text}
                                    comment={info.comment}
                                />
                                <div
                                    className="cover"
                                    onClick={() => {
                                        setCurrentQuestion(info)
                                        navigate('detail')
                                    }}
                                ></div>
                            </div>

                        )
                    })
                }
                <div className="bottom-space"></div>
            </div>
            <div className="shadow"></div>
            <div
                className="bottom-nav justify-center"
            >
                <Button
                    style={{
                        marginRight: '0.1rem',
                    }}
                    tips="不知道买什么酒？问问大家呢～"
                    onClick={() => {
                        if (lockPage.current === true) return
                        navigate('question')
                    }}
                />
                <Button
                    icon={require('@/assets/imgs/画笔.svg').default}
                    theme="white"
                    text="我的问答"
                    onClick={() => {
                        if (lockPage.current === true) return
                        navigate('center')
                    }}
                />
            </div>
        </div>
    )
}