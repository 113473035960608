import React, { useState } from 'react'
import CX from 'classnames'
import './index.less'

export default function NavigateSelect({
    style = {},
    onSelect = () => {}
}) {
    const [activeIndex, setActiveIndex] = useState(0)
    return (
        <div
            className="navigate-select-wrap"
            style={style}
        >
            {
                ['我提问的', '我参与的'].map((text, index) => {
                    return (
                        <div
                            key={index}
                            className={CX({
                                tab: true,
                                'items-center': true,
                                'justify-center': true,
                                active: activeIndex === index,
                            })}
                            style={{
                                marginLeft: index === 0 ? 0 : '0.18rem',
                            }}
                            onClick={() => {
                                setActiveIndex(index)
                                onSelect(index)
                            }}
                        >{text}</div>
                    )
                })
            }
        </div>
    )
}