import React, { useState } from 'react'
import CX from 'classnames'
import './index.less'

export default function SkuListItem({
    price = 899,
    title = '拉菲 传奇波尔多750ml 典藏版 拉菲 传奇波尔多典藏版 传奇',
    selected = false,
    disabled = false,
    style={},
    onSelect = () => {},
    imageUrl = require('@/assets/imgs/大V.svg').default
}) {
    const [isSelected, setIsSelected] = useState(selected)
    return (
        <div
            className={CX({
                'sku-list-item-wrap': true,
                'flex-row': true,
                'items-center': true,
                disabled,
            })}
            style={style}
            onClick={() => {
                if (!disabled) {
                    setIsSelected(!isSelected)
                    onSelect()
                }
            }}
        >
            <img
                className="picture"
                src={imageUrl}
            />
            <div
                className="info"
            >
                <div className="title">{title}</div>
                <div className="price">{'¥' + price}</div>
            </div>
            <div
                className={CX({
                    radio: true,
                    selected: isSelected,
                    'justify-center': true,
                    'items-center': true,
                })}
            >
                {
                    isSelected && <div className="inner" />
                }
            </div>
        </div>
    )
}