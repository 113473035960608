const isDev = false
const devHost = 'http://192.168.10.166:5000'
const releaseHost = 'https://tgb-jhqa-rc.isvjd.com'
const host = isDev ? devHost : releaseHost

// const defaultToken = 'KLGg7wTkNF1VF1jRfb45'
const tokenPrefix = 'KLGg7wTkNF1VF1jRfb45'

export {
    host,
    tokenPrefix,
}