import React, { useState, useRef, useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import './index.less'
import { SkuChooseContext } from '@/context/context'
import Search from '@/components/Search'
import SkuListItem from '@/components/SkuListItem'
import Button from '@/components/Button'
import { getAllProduct } from '@/server/productManager'

export default function ChooseSku() {
    const { getSelectedSku, setSelectedSku, setAllSkuList, allSkuList } = useContext(SkuChooseContext)
    const [skuList, setSkuList] = useState([])
    const [activeIndex, setActiveIndex] = useState(-1)
    const skuWrapRef = useRef(null)
    const searchAllList = useRef([])
    const { state } = useLocation()
    const navigate = useNavigate()
    
    useEffect(() => {

        if (allSkuList.length === 0) {
            getAllProduct().then((list) => {
                setAllSkuList(list)
            })
        } else {
            const idList = getSelectedSku().map(s => s.sku_id)
            // 每次取20条, 下拉刷新再取20条
            setSkuList(allSkuList.filter(as => idList.includes(as.sku_id) === false).slice(0, 20))
        }
    }, [allSkuList])

    useEffect(() => {
        // 滚动到底部，自动加载N条数据
        const _scroll = (e) => {
            const { scrollTop, scrollHeight, clientHeight } = e.target
            if (scrollTop + clientHeight + 10 >= scrollHeight) {
                if (searchAllList.current.length > 0) {
                    const len = skuList.length
                    const newSkuList = [...skuList]
                    setSkuList(newSkuList.concat(searchAllList.current.slice(len, len + 20)))
                } else {
                    const len = skuList.length
                    const newSkuList = [...skuList]
                    setSkuList(newSkuList.concat(allSkuList.slice(len, len + 20)))
                }
            }
        }
        skuWrapRef.current.addEventListener('scroll', _scroll, false)
        return () => {
            if (skuWrapRef.current) {
                skuWrapRef.current.removeEventListener('scroll', _scroll, false)
            }
        }
    }, [skuList, allSkuList])

    if (!window.userInfo) {
        setTimeout(() => {
            navigate('/')
        })
        return
    }

    return (
        <div className="choose-sku-wrap flex-col items-center">
            <Search
                onChangeEnd={(text) => {
                    const idList = getSelectedSku().map(s => s.sku_id)
                    const result = allSkuList.filter(as => idList.includes(as.sku_id) === false).filter(sku => sku.name.indexOf(text) > -1)
                    searchAllList.current = result
                    setSkuList(result.slice(0, 20))
                }}
                style={{
                    marginTop: '0.08rem',
                    marginBottom: '0.1rem'
                }}
            />
            <div
                className="sku-list"
                ref={skuWrapRef}
            >
                {
                    skuList.map((sku, index) => {
                        return (
                            <SkuListItem
                                price={sku.price}
                                title={sku.name}
                                imageUrl={sku.image_url}
                                key={index}
                                style={{
                                    marginTop: index === 0 ? 0 : '0.1rem',
                                }}
                                disabled={index !== activeIndex && activeIndex !== -1}
                                onSelect={() => {
                                    if (index === activeIndex) {
                                        setActiveIndex(-1)
                                    } else {
                                        setActiveIndex(index)
                                    }
                                }}
                            />
                        )
                    })
                }
                <div className="bottom-space"></div>
            </div>
            <div className="shadow"></div>
            <Button
                size="large"
                icon=""
                style={{
                    position: 'absolute',
                    bottom: '0.21rem',
                }}
                disabled={activeIndex === -1}
                text="确认"
                onClick={() => {
                    setSelectedSku(skuList[activeIndex], state.chooseIndex)
                    window.history.back()
                }}
            />
        </div>
    )
}