import React, { useRef, useState } from 'react'
import './index.less'

export default function Search({
    placeholder = '搜索商品',
    style = {},
    onChangeEnd = () => { },
}) {
    const timer = useRef(null)
    const inputRef = useRef(null)
    const [searchInput, setSearchInput] = useState('')
    return (
        <div
            className="search-wrap items-center"
            style={style}
        >
            <div
                className="items-center input-area"
            >
                <img
                    className="icon"
                    src={require('@/assets/imgs/放大镜.svg').default}
                    alt=""
                />
                <input
                    ref={inputRef}
                    className="input"
                    type="text"
                    placeholder={placeholder}
                    onFocus={() => { }}
                    onBlur={() => { }}
                    onChange={(e) => {
                        // 防抖
                        clearTimeout(timer.current)
                        timer.current = setTimeout(() => {
                            onChangeEnd(e.target.value)
                            setSearchInput(e.target.value)
                        }, 500)
                    }}
                />
                {
                    searchInput.length > 0 && (
                        <>
                            <img
                                className="clear"
                                src={require('@/assets/imgs/close.svg').default}
                                onClick={() => {
                                    inputRef.current.value = ''
                                    setSearchInput('')
                                    onChangeEnd('')
                                }}
                            />
                            <div className="search-btn items-center justify-center">搜索</div>
                        </>
                    )
                }
            </div>
        </div>
    )
}