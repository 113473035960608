import React, { useState } from 'react'
import CX from 'classnames'
import './index.less'

export default function Button({
    style = {},
    text = '我要提问',
    tips = '',
    icon = require('@/assets/imgs/画框.svg').default,
    theme = 'gold', // gold white
    size = 'middle', // middle large
    disabled = false,
    onClick = () => { }
}) {
    return (
        <div className="button-wrap" style={Object.assign({backgroundColor: '#fff'}, style)}>
            <div
                // style={style}
                className={CX({
                    'button-box': true,
                    'flex-row': true,
                    'justify-center': true,
                    'items-center': true,
                    gold: theme === 'gold',
                    white: theme === 'white',
                    middle: size === 'middle',
                    large: size === 'large',
                    disabled,
                })}
                onClick={() => {
                    if (disabled) return
                    onClick()
                }}
            >
                {
                    icon !== '' && (
                        <img
                            className="icon"
                            src={icon}
                            alt=""
                        />
                    )
                }
                {text}
                {
                    tips !== '' && (
                        <div
                            className="tips justify-center items-center"
                        >
                            {tips}

                            <img
                                src={require('@/assets/imgs/小三角.svg').default}
                                alt=""
                                className="after"
                            />
                        </div>
                    )
                }
            </div>
        </div>
    )
}