import React, { useMemo, useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import './index.less'
import QABox from '@/HOC/QABox'
import Comment from '@/components/Comment'
import Button from '@/components/Button'
import { useNavigate } from 'react-router-dom'
import { SkuChooseContext, QAContext } from '@/context/context'
import { ToastContext } from '../../context/Toast'
import { addProductVoter, getReplyAnswerList } from '@/server/QAManager'
import { getUserInfo } from '@/server/userManager'
import { toSku } from '@jmfe/jd-jssdk'
export default function QuestionDetail() {
    const [commentList, setCommentList] = useState([])
    const { getCurrentQuestion, setCurrentQuestion } = useContext(QAContext)
    const { show } = useContext(ToastContext)
    const navigate = useNavigate()
    const detail = useMemo(() => getCurrentQuestion(), [])

    useEffect(() => {
        // 获取该问题下的所有评论
        getReplyAnswerList(detail.id).then(async (res) => {
            res = res.filter((comment) => {
                return comment.audit_phase === 'pass'
            })
            for (let i = 0; i < res.length; i++) {
                let comment = res[i]
                const { user_name, user_avatar } = await getUserInfo(comment.user_id)
                comment = Object.assign(comment, {
                    user_name,
                    user_avatar,
                    isOfficial: false
                })
            }
            setCommentList(res)
        })
    }, [])

    if (!window.userInfo) {
        setTimeout(() => {
            navigate('/')
        })
        return
    }
    const selfVoteInfo = () => {
        // 如果用户投票了判断用户 给哪一边投了票
        let result = false
        if (detail.products.length === 0) return false
        for (let i = 0; i < detail.products[0].voters.length; i++) {
            if (detail.products[0].voters[i].user_name === window.userInfo.user_name) {
                result = 'left'
                break
            }
        }
        for (let i = 0; i < detail.products[1].voters.length; i++) {
            if (detail.products[1].voters[i].user_name === window.userInfo.user_name) {
                result = 'right'
                break
            }
        }
        return result
    }
    return (
        <div
            className="question-detail-wrap flex-col items-center"
        >
            <QABox
                pk={detail.products.length > 1 ? {
                    pro1: detail.products[0],
                    pro2: detail.products[1],
                } : {}}
                style={{
                    marginTop: '0.08rem',
                    marginBottom: '0.08rem',
                }}
                title={detail.text}
                onVoteRight={(cb) => {
                    addProductVoter(window.userInfo, detail.id, 1).then(() => {
                        cb()
                    }, () => {
                        show('投票失败!')
                    })
                }}
                onVoteLeft={(cb) => {
                    addProductVoter(window.userInfo, detail.id, 0).then(() => {
                        cb()
                    }, () => {
                        show('投票失败!')
                    })
                }}
                disabled={detail.audit_phase !== 'pass'}
                defaultLeftVoted={selfVoteInfo() === 'left'}
                defaultRightVoted={selfVoteInfo() === 'right'}
                onClickLeft={() => {
                    toSku(detail.products[0].sku_id)
                }}
                onClickRight={() => {
                    toSku(detail.products[1].sku_id)
                }}
            />
            <div
                className="comments-list"
            >
                {
                    commentList.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className="detail"
                                style={{
                                    marginTop: index === 0 ? 0 : '0.08rem',
                                }}
                            >
                                <Comment
                                    style={{
                                        width: '3.35rem',
                                    }}
                                    nickName={item.user_name}
                                    avatar={item.user_avatar}
                                    content={item.text}
                                    isOfficial={item.user_id === window.officialInfo.user_id}
                                    showIcon={false}
                                    key={index}
                                    skuList={JSON.parse(item.product_recommend)}
                                    onClickSku={(val) => {
                                        toSku(val.sku_id)
                                    }}
                                />
                            </div>

                        )
                    })
                }

                {
                    commentList.length === 0 && (
                        <div className="empty">
                            <div>暂时还没有回答</div>
                            <div>“快来抢沙发吧”</div>
                        </div>
                    )
                }
            </div>

            <div className="bottom-nav justify-center">
                <Button
                    style={{
                        marginRight: '0.1rem',
                    }}
                    onClick={() => {
                        navigate('/question')
                    }}
                />
                <Button
                    icon={require('@/assets/imgs/画笔.svg').default}
                    theme="white"
                    text="我要回答"
                    onClick={() => {
                        navigate('/answer')
                    }}
                />
            </div>
        </div>
    )
}