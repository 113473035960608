import React, { useRef, useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import './index.less'
import IconTitle from '@/components/IconTitle'
import TextArea from '@/components/TextArea'
import Button from '@/components/Button'
import AddSkuCard from '@/HOC/AddSkuCard'
import { SkuChooseContext, ToastContext, QAContext } from '@/context/context'
import { replyQuestion } from '@/server/QAManager'
import { sendEmail } from '@/server/mailManager'
import { isIncludeSensitive } from '@/utils'

export default function AnswerPage() {
    const textAreaRef = useRef(null)
    const [answerContent, setAnswerContent] = useState('')
    const [skuInfoList, setSkuInfoList] = useState([{}, {}, {}])
    const { deleteSelectedSku, initSelectedSku, getSelectedSku } = useContext(SkuChooseContext)
    const navigate = useNavigate()
    const { show } = useContext(ToastContext)
    const { getCurrentQuestion, getSaveAnswerText, setSaveAnswer } = useContext(QAContext)
    const detail = getCurrentQuestion()
    useEffect(() => {
        initSelectedSku(3) // 初始化三个坑位
        const list = []
        // 获取所有从商品选择页面选择的商品
        const selectedList = getSelectedSku()
        for (let i = 0; i < selectedList.length; i++) {
            list.push(selectedList[i] || {})
        }
        setSkuInfoList(list)
        const saveText = getSaveAnswerText()
        setAnswerContent(saveText)
        textAreaRef.current.elem.value = saveText
    }, [])
    if (!window.userInfo) {
        setTimeout(() => {
            navigate('/')
        })
        return
    }
    
    return (
        <div className="answer-page flex-col items-center">
            <IconTitle
                title={detail.text}
                style={{
                    margin: '0.16rem 0 0.08rem 0',
                }}
            />
            <TextArea
                placeholder="请输入回答"
                onChange={(value) => {
                    setAnswerContent(value)
                    setSaveAnswer(value)
                }}
                ref={textAreaRef}
            />
            <IconTitle
                style={{
                    margin: '0.12rem 0'
                }}
                IconElem={<img
                    className="black-ten"
                    src={require('@/assets/imgs/黑色背景十.svg').default}
                />}
                title="选择添加商品（最多3个）"
            />
            <div
                className="sku-card-wrap justify-between"
            >
                {
                    skuInfoList.map((skuInfo, index) => {
                        return <AddSkuCard
                            skuInfo={skuInfo}
                            key={index}
                            onAdd={() => {
                                navigate('/choose', {
                                    state: {
                                        navigatePage: '/answer',
                                        chooseIndex: index,
                                    }
                                })
                            }}
                            onDelete={() => {
                                deleteSelectedSku(index)
                                setSkuInfoList([...getSelectedSku()])
                            }}
                        />
                    })
                }
            </div>
            <Button
                style={{
                    position: 'absolute',
                    bottom: '0.21rem',
                }}
                disabled={answerContent.length === 0}
                text="发布回答"
                icon={require('@/assets/imgs/纸飞机.svg').default}
                size="large"
                onClick={() => {
                    if (isIncludeSensitive(answerContent)) {
                        show('内容包含敏感词汇', 1200)
                    } else {
                        replyQuestion({
                            question_id: detail.id,
                            user_name: window.userInfo.user_name,
                            user_id: window.userInfo.user_id,
                            text: answerContent,
                            product_recommend: JSON.stringify(skuInfoList),
                            audit_phase: 'pending',
                        }).then(() => {
                            sendEmail()
                            show('回答成功，等待审核', 800, () => {
                                window.history.back()
                            })
                        })
                    }
                }}
            />
        </div>
    )
}