import superagent from 'superagent'
import { encrypt } from '@/utils/des'
import { host, tokenPrefix } from './host'

export const getEmail = () => {
    return new Promise((resolve, reject) => {
        superagent.get(`${host}/mail/config`)
            .set('token', encrypt(`${tokenPrefix}.mail`))
            .send({})
            .then((res) => {
                resolve(res.body)
            }, (err) => {
                reject(err)
            })
    })
}

export const udpateEmail = (new_mail_list) => {
    return new Promise((resolve, reject) => {
        getEmail().then(({ data }) => {
            data.mail_recipient = new_mail_list
            superagent.post(`${host}/mail/config`)
                .set('token', encrypt(`${tokenPrefix}.mail`))
                .send(data)
                .then((res) => {
                    resolve(res.body)
                }, (err) => {
                    reject(err)
                })
        })
        
    })
}

export const sendEmail = () => {
    return new Promise((resolve, reject) => {
        getEmail().then(({ data }) => {
            superagent.post(`${host}/mail/send`)
                .set('token', encrypt(`${tokenPrefix}.mail`))
                .send({
                    title: 'To 管理员',
                    content: '有新的审核待确认',
                    recipient: data.mail_recipient
                })
                .then((res) => {
                    resolve()
                }, (err) => {
                    reject(err)
                })
        })
        
    })
}