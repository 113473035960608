import superagent from 'superagent'

export const getProduct = (id = '21772985') => {
    return new Promise((resolve, reject) => {
        superagent.get('https://api.m.jd.com/client.action?functionId=qryCompositeMaterials&body=' + JSON.stringify({
            applyKey: 'big_promotion',
            activityId: '2royoYfkou2NK8cKd8AJm6DcUKcp',
            pageId: '',
            qryParam: [{
                next: [],
                handPriceRatio: 100,
                mapTo: 'data1_1',
                type: 'productGroup',
                id,
            }]
        }) +  '&client=wh5&clientVersion=1.0.0&networkType=4g&sid=null&uuid=16902537242691610828923&t=' + Date.now())
            .send({})
            .then((res) => {
                // console.log(, 'aslkjdajsdlaksjdlak')
                resolve(JSON.parse(res.text))
            }, (err) => {
                reject(err)
            })
    })
}

export const getAllProduct = () => {
    return new Promise((resolve, reject) => {
        const productIdGrp = ['21772985', '21772995', '21772998', '21772999', '21773001', '21773004']
        const promiseList = productIdGrp.map(id => getProduct(id))
        Promise.all(promiseList).then(res => {
            let result = []
            res.forEach((item, index) => {
                if (item.code === '0') {
                    const { list } = item.data.data1_1
                    result.push(...list)
                }
            })
            resolve(result.map((sku, skuIndex) => {
                return {
                    sku_id: sku.skuId,
                    name: sku.name,
                    image_url: sku.image,
                    price: sku.pPrice,
                }
            }))
        }, (err) => {
            reject(err)
        })
    })
}
