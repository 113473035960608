import sensitive from '@/consts/sensitive'
export const isIncludeSensitive = (word) => {
    let result = false
    for (let i = 0; i < sensitive.length; i++) {
        const target = sensitive[i]
        if (word.indexOf(target) > -1) {
            result = true
            break
        }
    }
    return result
}